import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'

class TopSlider extends React.Component {

    render() {
        let settings = {
            autoplay: true,
            autoplaySpeed: 4000,
            arrows: true,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        return (
            <Slider {...settings}>
                {this.props.children}
            </Slider>
        );
    }

}

export default TopSlider
