import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Helmet from 'react-helmet'

import Logo from '-!svg-react-loader!../content/svg/burdasolutions-logo.svg'
import Search from './search'
import PropTypes from 'prop-types'

function Header(props) {
    const [navOpen, setNavOpen] = useState(false)
    const data = useStaticQuery(graphql`
                    query {
                        search: siteSearchIndex {
                            index
                            storage
                        }
                        paths: allSitePage {
                            edges {
                                node {
                                    path
                                }
                            }
                        }
                    }
                `)

    const handleOpenClick = () => setNavOpen(true)

    const handleCloseClick = () => setNavOpen(false)

    const renderHeader = ({ index, storage }, paths) => {
        const Link = props.link
        const slugs = paths.edges.map(({ node }) => node.path)
        const hasChildren = props.children

        return (
          <header>
              <Helmet>
                  <body className={navOpen ? 'mobile-menu-exposed' : ''} />
              </Helmet>

              <Link to='/kontakt' className='sticky-contact' title='Kontakt'>Kontakt</Link>

              <div className='container'>
                  <div className='row'>
                      <div className='col-6 col-sm-8 col-lg-2 col-xl-3'>
                          <Link to='/' className='company-logo' title='Home' onClick={handleCloseClick}>
                              <Logo />
                          </Link>
                      </div>

                      <div className='col-6 col-sm-4 col-lg-10 col-xl-9'>
                          <div className='row'>
                              <div className='col-0 col-lg-9 col-xl-8'>
                                  <nav className={navOpen ? 'mobile-menu-exposed' : ''}>
                                      <div id='main-navigation'>
                                          <div className='mobile-menu-close-btn d-lg-none' onClick={handleCloseClick}>
                                              <i className='icon remove' />
                                          </div>

                                          <ul>
                                              <li className='mobile-menu-search'>
                                                  <Search
                                                    searchIndex={index}
                                                    storage={storage}
                                                    slugs={slugs}
                                                    onLinkClick={handleCloseClick}
                                                    key={props.location.pathname}
                                                    language={props.language}
                                                    searchPlaceholder={props.searchPlaceholder}
                                                    searchResultsLabel={props.searchResultsLabel}
                                                    link={Link}
                                                  />
                                              </li>

                                              {
                                                  props.mainNav.map((item, index) =>
                                                    <li key={`${props.location.pathname}-${index}`}>
                                                        <Link to={item.path} onClick={handleCloseClick}
                                                              className={item.subNav ? 'has-sub-cats' : ''}>{item.label}</Link>

                                                        {item.subNav && (
                                                          <div className='flyout'>
                                                              <div className='sub-nav-list'>
                                                                  <ul>
                                                                      {item.subNav.map(subItem =>
                                                                        <li key={subItem.label}>
                                                                            <Link to={subItem.path}
                                                                                  onClick={handleCloseClick}>{subItem.label}</Link>
                                                                        </li>,
                                                                      )}
                                                                  </ul>
                                                              </div>
                                                          </div>
                                                        )}
                                                    </li>)
                                              }

                                              <li className='mobile-menu-contact'>
                                                  <Link to='/kontakt' title='Kontakt'
                                                        onClick={handleCloseClick}>Kontakt</Link>
                                              </li>
                                          </ul>
                                      </div>
                                  </nav>
                              </div>

                              {hasChildren
                                ?
                                <div className='col-8 col-lg-1 col-xl-1'>
                                    {props.children}
                                </div>
                                :
                                null
                              }

                              <div
                                className={`d-none d-lg-block col-lg-${hasChildren ? '2' : '3'} col-xl-${hasChildren ? '3' : '4'}`}>
                                  <Search
                                    searchIndex={index}
                                    storage={storage}
                                    slugs={slugs}
                                    onLinkClick={handleCloseClick}
                                    key={props.location.pathname}
                                    language={props.language}
                                    searchPlaceholder={props.searchPlaceholder}
                                    searchResultsLabel={props.searchResultsLabel}
                                    link={Link}
                                  />
                              </div>

                              <div className={`col-${hasChildren ? '4' : '12'} d-lg-none`}>
                                  <div className='mobile-menu-btn' onClick={handleOpenClick}>
                                      <i className='icon menu' />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </header>
        );
    }
    return renderHeader(data.search, data.paths)
}

Header.propTypes = {
    mainNav: PropTypes.array,
    searchPlaceholder: PropTypes.string,
    searchResultsLabel: PropTypes.string,
    link: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

export default Header
