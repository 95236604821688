import React from 'react'
import {Link as GatsbyLink} from 'gatsby'

const Link = ({linkToMainSite, to, title, children}) => (
    <>
        {linkToMainSite ?
            <a href={`https://www.burdasolutions.com${to}`} title={title} target="_blank" rel="noreferrer noopener">
                {children}
            </a>
            :
            <GatsbyLink to={to} title={title}>{children}</GatsbyLink>
        }
    </>
);

const Footer = ({linkToMainSite, imprintLabel, contactLabel, dataProtectionLabel, ContactLink}) => (
    <footer>
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <ul className="footer-links">
                        <li><ContactLink to="/kontakt" title={contactLabel}>{contactLabel}</ContactLink></li>
                        <li><Link linkToMainSite={linkToMainSite} to="/impressum" title={imprintLabel}>{imprintLabel}</Link></li>
                        <li><Link linkToMainSite={linkToMainSite} to="/datenschutz" title={dataProtectionLabel}>{dataProtectionLabel}</Link></li>
                    </ul>
                </div>

                <div className="col-lg-6">
                    <div className="footer-connect">
                        Connect with BurdaSolutions

                        <div className="connections">
                            <a href="https://www.xing.com/companies/burdadigitalsystemsgmbh" title="Xing" target="_blank">
                                <i className="icon xing" />
                            </a>

                            <a href="https://www.kununu.com/de/burda-digital-systems" title="kununu" target="_blank">
                                <i className="icon kununu" />
                            </a>

                            <a href="https://www.linkedin.com/company/burda-digital-systems-gmbh" title="LinkedIn" target="_blank">
                                <i className="icon linkedin" />
                            </a>

                            <a href="https://www.instagram.com/burdasolutions/" title="Instagram" target="_blank">
                                <i className="icon instagram" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-6">
                    <div className="footer-copyright">
                        © Burda Digital Systems GmbH
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer
