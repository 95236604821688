import React from 'react'
import {Link, graphql} from 'gatsby'
import {GatsbyImage} from "gatsby-plugin-image"

const PageTeaser = ({image, title, link}) => (
    <div className="col-12 col-md-6 col-lg-4">
        <Link to={link} className="tile" title={"Weitere Informationen zu " + title}>
            <div className="tile-image">
                <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt="" />
            </div>

            <div className="tile-info">
                <div className="tile-title">{title}</div>

                <div className="show-more">Mehr erfahren</div>
            </div>
        </Link>
    </div>
);

export default PageTeaser;

export const PageTeaserFragment = graphql`
    fragment PageTeaserFragment on MarkdownRemark {   
        fields {
            slug
        }
        frontmatter {
            teaserTitle
            teaserImage {
                childImageSharp {
                    gatsbyImageData(width: 600, layout: CONSTRAINED)
                }
            }
        }
    }
`;
