import React from 'react'
import {Link, graphql} from 'gatsby'

class Slide extends React.Component {

    render() {
        const slide = (
            <div className={`image-box ${this.props.imageAlignment}`} style={{backgroundImage: `url(${this.props.image.childImageSharp.gatsbyImageData.images.fallback.src})`}}>
                {this.props.title && (
                    <div className="container">
                        <div className="slide-content-box">
                            <h2>{this.props.title}</h2>

                            <p>{this.props.subTitle}</p>
                        </div>
                    </div>
                )}
            </div>
        );

        return this.props.link
            ? (<Link to={this.props.link}>{slide}</Link>)
            : slide
        ;
    }
}

export default Slide

export const fragment = graphql`
    fragment SlideFragment on MarkdownRemark {
        frontmatter {
            title
            slides {
                title
                subTitle
                link
                imageAlignment
                image {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 90, layout: FIXED)
                    }
                }
            }
        }
    }
`;
