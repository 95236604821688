import React from 'react';
import {Link} from 'gatsby';
import {range} from 'lodash';

const boundaryRange = 1, siblingRange = 1, ellipsisSize = 1;

const isSimplePagination = (totalPages) => {
    const boundaryRangeSize = 2 * boundaryRange;
    const siblingRangeSize = 2 * siblingRange;

    return 1 + ellipsisSize * 2 + siblingRangeSize + boundaryRangeSize >= totalPages;
};

const createInnerPrefix = (firstGroupEnd, innerGroupStart) => {
    const prefixPage = innerGroupStart - 1;
    const showEllipsis = prefixPage !== firstGroupEnd + 1;
    return showEllipsis ? "&hellip;" : prefixPage;
};

const createInnerSuffix = (innerGroupEnd, lastGroupStart) => {
    const suffixPage = innerGroupEnd + 1;
    const showEllipsis = suffixPage !== lastGroupStart - 1;
    return showEllipsis ? "&hellip;" : suffixPage;
};

const createSimpleRange = (start, end) => range(start, end + 1);

const createComplexRange = (activePage, totalPages) => {
    const firstGroupEnd = boundaryRange;
    const firstGroup = createSimpleRange(1, firstGroupEnd);

    const lastGroupStart = totalPages + 1 - boundaryRange;
    const lastGroup = createSimpleRange(lastGroupStart, totalPages);

    const innerGroupStart = Math.min(
      Math.max(activePage - siblingRange, firstGroupEnd + ellipsisSize + 1),
      lastGroupStart - ellipsisSize - 2 * siblingRange - 1
    )
    const innerGroupEnd = innerGroupStart + 2 * siblingRange;

    const innerGroup = createSimpleRange(innerGroupStart, innerGroupEnd);

    return [
        ...firstGroup,
        createInnerPrefix(firstGroupEnd, innerGroupStart),
        ...innerGroup,
        createInnerSuffix(innerGroupEnd, lastGroupStart),
        ...lastGroup,
    ];
};

const createRange = (activePage, totalPages) =>
  isSimplePagination(totalPages) ? createSimpleRange(1, totalPages) : createComplexRange(activePage, totalPages);

const NavLink = ({url, renderLink, label}) => {
    if (renderLink) {
        return <Link to={url}>{label}</Link>;
    } else {
        return <span className="active">{label}</span>;
    }
};

const Pagination = ({index, prefix, pages}) => (
    <div className="pagination">
        {createRange(index, pages.length).map((page, pagerIndex) => (
            page !== "&hellip;"
              ? <NavLink key={pagerIndex} renderLink={index !== page} url={page === 1 ? prefix : `${prefix}/${page}`} label={page} />
              : <span key={pagerIndex} dangerouslySetInnerHTML={{__html: page}} />
        ))}
    </div>
);

export default Pagination
