import React from 'react'
import {uniqBy} from 'lodash'
import lunr from 'lunr'
import stemmer from 'lunr-languages/lunr.stemmer.support'
import de from 'lunr-languages/lunr.de'

const getParentSlug = (path) => (path.substring(0, path.lastIndexOf('/')));

const getDocBySlugAndLanguage = (storage, slug, currentLanguage) => {
    const hits = Object.keys(storage).filter(key => storage[key].slug === slug && (!currentLanguage || storage[key].language === currentLanguage)).map(key => storage[key]);
    if (hits.length) {
        return hits[0];
    }
    return null;
}

const findPage = (ref, storage, slugs, currentLanguage) => {
    let slug = storage[ref].slug;
    while (slug.length && !slugs.includes(slug)) {
        slug = getParentSlug(slug);
    }
    if (storage[ref].language === currentLanguage) {
        return getDocBySlugAndLanguage(storage, slug, currentLanguage);
    }
    return null;
};

class Search extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            queryString: '',
            results: [],
        }
    }

    render() {
        const Link = this.props.link;
        return (
            <div className={this.state.queryString.length > 0 ? "search search-remove" : "search" }>
                <input type="text" placeholder={this.props.searchPlaceholder} value={this.state.queryString} onChange={this.search} />

                <div className="search-icon">
                    <i className="icon loupe"/>
                </div>

                <div className="search-remove-btn" onClick={this.reset}>
                    <i className="icon remove"/>
                </div>

                {this.state.results.length > 0 && (
                    <div className="search-results">
                        <div className="container">
                            <strong>{this.props.searchResultsLabel}</strong>

                            <ul>
                                {this.state.results.map(item => (
                                    <li key={item.id}><Link onClick={this.linkClicked} to={item.slug}>{item.title}</Link></li>)
                                )}
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    getOrCreateIndex = () => {
        if (this.index) {
            return this.index
        } else {
            this.setupDeIfNeeded();
            return lunr.Index.load(this.props.searchIndex);
        }
    };

    setupDeIfNeeded = () => {
        if (!('stemmer-de' in lunr.Pipeline.registeredFunctions)) {
            stemmer(lunr);
            de(lunr);
        }
    };

    getResults = (queryString) => {
        const hits = this.index.query(
            query => {
                lunr.tokenizer(queryString).forEach(function (token) {
                    query.clause({term: token.toString(), usePipeline: true, presence: lunr.Query.presence.REQUIRED})
                })
            }
        ).map(({ref}) => findPage(ref, this.props.storage, this.props.slugs, this.props.language)
        ).filter(storage => storage !== null);

        return uniqBy(hits, item => item.slug);
    };

    search = (evt) => {
        const queryString = evt.target.value;
        this.index = this.getOrCreateIndex();

        if (queryString.length > 0) {
            this.setState({
                queryString: queryString,
                results: this.getResults(queryString)
            })
        } else {
            this.reset();
        }
    };

    linkClicked = () => {
        this.reset();
        if (this.props.onLinkClick) {
            this.props.onLinkClick()
        }
    };

    reset = () => {
        this.setState({
            queryString: '',
            results: []
        })
    }
}

export default Search
