import React from "react";
import {Link as GatsbyLink} from "gatsby";

const Breadcrumbs = ({breadcrumb, label, link}) => {
    if (breadcrumb && breadcrumb.length) {
        let BreadcrumbLink = GatsbyLink;

        if (link) {
            BreadcrumbLink = link;
        }
        return (
            <div id="breadcrumb" className="container">
                <ul>
                    {breadcrumb.map(
                        (breadcrumbItem, index) => (
                            <li key={`breadcrumb-${index}`}>
                                <BreadcrumbLink to={breadcrumbItem.slug}>
                                    {breadcrumbItem.label}
                                </BreadcrumbLink>
                            </li>
                        )
                    )}
                    <li>{label}</li>
                </ul>
            </div>
        )
    }

    return null;
};

export default Breadcrumbs;
