import React from 'react'
import Helmet from "react-helmet";
import TopSlider from './top-slider'
import Slide from './slide'
import Breadcrumbs from "./breadcrumbs";

const MarkdownPage = ({frontmatter, html, noGap, noHeading, link, breadcrumb, children}) => (
    <>
        <Helmet htmlAttributes={{prefix: "og: http://ogp.me/ns#"}}
            title={frontmatter.title}
            meta={[
                { property: "og:title", content: frontmatter.heading }
            ]}
        />

        <div className="hero-slider">
            <TopSlider>
                {frontmatter.slides.map((slide, index) => (
                    <Slide key={index} imageAlignment={slide.imageAlignment ? slide.imageAlignment : ''} image={slide.image} title={slide.title} subTitle={slide.subTitle} link={slide.link}/>
                ))}
            </TopSlider>
        </div>

        <Breadcrumbs breadcrumb={breadcrumb} link={link} label={frontmatter.navLabel} />

        <div id="content" className={noGap ? 'no-gap' : ''}>
            {noHeading ? null : <div className="page-head">
                <div className="container">
                    <h1>{frontmatter.heading}</h1>
                </div>
            </div>}

            <div className="container">
                {html ? <div className="page-text" dangerouslySetInnerHTML={{__html: html}} /> : null }

                { children }
            </div>

        </div>
    </>
);

export default MarkdownPage
