import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { truncate } from 'lodash'

import { Footer, Header, Transition } from '../../../../packages/gatsby-shared'

import '../../../../packages/gatsby-shared/scss/styles.scss'

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
            query SiteTitleQuery {
                site {
                    siteMetadata {
                        title,
                        description,
                        siteUrl
                        mainNav {
                            label
                            path
                            subNav {
                                label
                                path
                            }
                        }
                    }
                }
                logo: file(relativePath: {regex: "/burdasolutions-logo.png/"}) {
                    childImageSharp {
                        gatsbyImageData(width: 1200, height: 630, layout: FIXED)
                    }
                }
            }
        `)

  return <>
    <Helmet htmlAttributes={{ prefix: 'og: http://ogp.me/ns#' }}
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: data.site.siteMetadata.description },
              { property: 'og:locale', content: 'de_DE' },
              { property: 'og:type', content: 'website' },
              { property: 'og:title', content: data.site.siteMetadata.title },
              {
                property: 'og:description',
                content: truncate(data.site.siteMetadata.description, { 'length': 65 }),
              },
              {
                property: 'og:url',
                content: `${data.site.siteMetadata.siteUrl}${location.pathname}`,
              },
              {
                property: 'og:image',
                content: `${data.site.siteMetadata.siteUrl}${data.logo.childImageSharp.gatsbyImageData.images.fallback.src}`,
              },
              {
                property: 'og:image:width',
                content: data.logo.childImageSharp.gatsbyImageData.width,
              },
              {
                property: 'og:image:height',
                content: data.logo.childImageSharp.gatsbyImageData.height,
              },
            ]}
    >
      <html lang='de' />
    </Helmet>

    <Header
      mainNav={data.site.siteMetadata.mainNav}
      location={location}
      link={Link}
      searchPlaceholder='Suche'
      searchResultsLabel='Suchvorschläge'
    />

    <Transition location={location}>
      {children}
    </Transition>

    <Footer imprintLabel='Impressum' contactLabel='Kontakt' dataProtectionLabel='Hinweise zum Datenschutz'
            ContactLink={Link} />
  </>
}

Layout.propTypes = {
  location: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default Layout
